var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorProfileDelivery column" },
    [
      _c("base-delivery-requirements", {
        attrs: { debtor: _vm.debtor },
        on: { save: _vm.saveDeliveryReqs }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }