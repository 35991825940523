<template>
  <div class="DebtorProfileDelivery column">
    <base-delivery-requirements
      @save="saveDeliveryReqs"
      :debtor="debtor"
    />
  </div>
</template>

<script>
// Helpers
import { Debtor } from '../../../utils/api'
import { formatPhoneNumber } from '../../../utils/helpers'
// Components
import BaseDeliveryRequirements from '../../../components/base-delivery-requirements.vue'

export default {
  name: 'DebtorProfileDelivery',

  components: {
    BaseDeliveryRequirements,
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },
  },

  methods: {
    async saveDeliveryReqs (methods) {
      this.progressStart()

      try {
        // Passing "undefined" is required for values that have a checkbox that sets whether the
        // method was checked "required," because we don't actually want to update the debtor's
        // value for that method

        // Passing "null" will actually update the value, which is required for "pay_status_emails"

        const updatedDebtor = (await Debtor.update({
          // If a value was not a checked method, do not update it's value
          fax: methods.fax.required
            ? formatPhoneNumber(methods.fax.value)
            : undefined,
          id: this.debtor.id,
          // Check for null before running .split
          invoice_delivery_emails: methods.invoice_delivery_emails.required
            ? methods.invoice_delivery_emails.value.toLowerCase().split(',').map(email => email.trim())
            : undefined,
          mailing_address: methods.mail.required.copies || methods.mail.required.originals
            ? methods.mail.value.address
            : undefined,
          mailing_address_2: methods.mail.required.copies || methods.mail.required.originals
            ? methods.mail.value.address_2
            : undefined,
          mailing_city: methods.mail.required.copies || methods.mail.required.originals
            ? methods.mail.value.city
            : undefined,
          mailing_state: methods.mail.required.copies || methods.mail.required.originals
            ? methods.mail.value.state
            : undefined,
          mailing_zip: methods.mail.required.copies || methods.mail.required.originals
            ? methods.mail.value.zip
            : undefined,
          noa_emails: methods.noa_emails.value.toLowerCase().split(',').map(email => email.trim()),
          online_submit_url: methods.online.required
            ? methods.online.value
            : undefined,
          // Not required by base-delivery-requirements, must check for existing value not "required"
          // Pass "null" instead of undefined to actually remove it since there isn't a checkbox
          // to toggle the value to be passed or not
          pay_status_emails: methods.pay_status_emails.value
            ? methods.pay_status_emails.value.toLowerCase().split(', ')
            : null,
          require_copies: methods.mail.required.copies,
          require_email: methods.invoice_delivery_emails.required,
          require_fax: methods.fax.required,
          require_online_submit: methods.online.required,
          require_originals: methods.mail.required.originals,
        })).data
        this.$store.commit('UPDATE_STORED_DEBTOR', updatedDebtor)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Profile Delivery "Save Delivery Reqs"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the debtor' })
      }
    },
  }
}
</script>

<style lang="sass">
.DebtorProfileDelivery

  .BaseInput
    width: rem(210px)

  &__base
    .column
      margin-right: rem(30px)
</style>