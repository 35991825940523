var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorProfilePaymentStatusPreferences column" },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [
          _vm._v("Preferences")
        ]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            attrs: { "data-cy": "debtor-profile-payment-status-edit-btn" },
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "base", staticClass: "row" }, [
                _vm.debtor.payment_status_preferences
                  ? _c("label", { staticClass: "fs-14" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.debtor.payment_status_preferences) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.editing
            ? _c(
                "div",
                { key: "edit", staticClass: "column" },
                [
                  _c("base-textarea", {
                    attrs: {
                      "data-cy": "debtor-profile-payment-status-input",
                      label: true,
                      "label-for": "debtor-payment-status-preferences",
                      value: _vm.debtor.payment_status_preferences
                    },
                    model: {
                      value: _vm.duplicateUser.payment_status_preferences.value,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.duplicateUser.payment_status_preferences,
                          "value",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "duplicateUser.payment_status_preferences.value"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "row row--align-center mt-10" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-6",
                          attrs: {
                            "data-cy": "debtor-profile-payment-status-save-btn"
                          },
                          on: {
                            click: _vm.saveDebtor,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.saveDebtor($event)
                            }
                          }
                        },
                        [_vm._v("\n          Save\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white",
                          on: {
                            click: _vm.cancel,
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.cancel($event)
                            }
                          }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }