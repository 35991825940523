var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorProfile" },
    [
      _vm.debtor.broker
        ? [
            _c("user-profile-authority", { attrs: { "page-p-o-v": "debtor" } }),
            _c("hr", { staticClass: "Divider" })
          ]
        : _vm._e(),
      _c("user-profile-company", { attrs: { "page-p-o-v": "debtor" } }),
      _c("hr", { staticClass: "Divider" }),
      _c("debtor-profile-delivery"),
      _c("hr", { staticClass: "Divider" }),
      _c("debtor-profile-payment-status-preferences")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }