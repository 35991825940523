<template>
  <div class="DebtorProfile">
    <template v-if="debtor.broker">
      <user-profile-authority :page-p-o-v="'debtor'" />

      <hr class="Divider">
    </template>

    <user-profile-company :page-p-o-v="'debtor'" />

    <hr class="Divider">

    <debtor-profile-delivery />

    <hr class="Divider">

    <debtor-profile-payment-status-preferences />
  </div>
</template>

<script>
// Components
import DebtorProfileDelivery from './debtor-profile-delivery.vue'
import DebtorProfilePaymentStatusPreferences from './debtor-profile-payment-status-preferences.vue'
import UserProfileAuthority from '../../../components/user-profile-authority.vue'
import UserProfileCompany from '../../../components/user-profile-company.vue'

export default {
  name: 'DebtorProfile',

  components: {
    DebtorProfileDelivery,
    DebtorProfilePaymentStatusPreferences,
    UserProfileAuthority,
    UserProfileCompany,
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    }
  },
}
</script>

<style lang="sass">
.DebtorProfile
  margin-top: rem(42px)
  padding: 0 rem(29px) 2.5rem

  & > div,
  .Divider
    margin-bottom: rem(42px)

    &:last-child
      margin-bottom: 0

</style>