<template>
  <div class="DebtorProfilePaymentStatusPreferences column">
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Preferences</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
        data-cy="debtor-profile-payment-status-edit-btn"
      >
        Edit
      </button>
    </div>

    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- BASE -->
      <div
        v-if="!editing"
        class="row"
        key="base"
      >
        <label
          v-if="debtor.payment_status_preferences"
          class="fs-14"
        >
          {{ debtor.payment_status_preferences }}
        </label>
      </div>

      <!-- EDITING -->
      <div
        v-if="editing"
        class="column"
        key="edit"
      >
        <base-textarea
          v-model.trim="duplicateUser.payment_status_preferences.value"
          data-cy="debtor-profile-payment-status-input"
          :label="true"
          :label-for="'debtor-payment-status-preferences'"
          :value="debtor.payment_status_preferences"
        />
        <div class="row row--align-center mt-10">
          <base-button
            @click="saveDebtor"
            @keydown.enter="saveDebtor"
            class="bg-blue fc-white mr-6"
            data-cy="debtor-profile-payment-status-save-btn"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import { Debtor } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseTextarea from '../../../components/base-textarea.vue'

export default {
  name: 'DebtorProfilePaymentStatusPreferences',

  components: {
    BaseButton,
    BaseTextarea,
  },

  data () {
    return {
      duplicateUser: null,
      editing: false
    }
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    nowEditing () {
      this.duplicateUser = {
        payment_status_preferences: {
          valid: true,
          value: this.debtor.payment_status_preferences
        },
      }
      this.editing = true
    },

    async saveDebtor () {
      this.progressStart()

      try {
        const updatedDebtor = (await Debtor.update({
          id: this.debtor.id,
          payment_status_preferences: this.duplicateUser.payment_status_preferences.value
        })).data

        this.$store.commit('UPDATE_STORED_DEBTOR', updatedDebtor)
      } catch (error) {
        this.captureSentryEvent(
          'Debtor Profile Payment Status "Save Debtor"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the debtor' })
      }

      this.editing = false
      this.progressFinish()
    }
  }
}
</script>

<style lang="sass">
.DebtorProfilePaymentStatusPreferences

  .BaseTextarea
    width: 50%

    &__input
      resize: vertical
</style>